import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'

interface SupplierFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
}

const $state: SupplierFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  name: '',
  type: '',
  feedback_merchant: false,
  mark_merchant: false,
  feedback_product: false,
  mark_product: false,
  item_sum: 0,
  receipt_sum: 0,
  accural_start: 'auto',
  allow_accural_auto_start: true,
  allow_accural_manual_start: true,
  can_request_product_feedback: true,
  can_request_receipt_feedback: true,
}

const AVAILABLE_FIELDS = [
  'name', 'type', 'feedback_merchant', 'mark_merchant', 'feedback_product',
  'mark_product', 'receipt_sum', 'item_sum', 'accural_start', 'allow_accural_auto_start',
  'allow_accural_manual_start', 'can_request_product_feedback', 'can_request_receipt_feedback',
]

export const $actions: ActionTree<SupplierFormState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    commit('START_LOADING')
    return new Promise((resolve, reject) => {
      if (id) {
        const vm = (this as any)._vm

        vm.$http
          .get(`/admins/rest/v1/suppliers/${id}`)
          .then((response: AxiosResponse) => {
            commit('SET_FORM_DATA', {
              object: response.data.supplier,
            })
            commit('LOADED_SUCCESS')

            resolve(state.object)
          })
          .catch((error: AxiosError) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error.response?.data)
            commit('LOADED_ERROR')
            reject(error.response?.data)
          })
      }
      else {
        return new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', predefined)
          commit('LOADED_SUCCESS')
          resolve(state.object)
        })
      }
    })
  },

  prepareNew({ commit }, predefined) {
    return new Promise((resolve) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve(null)
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  addAttachments({ commit }, newValue) {
    commit('CHANGE_VALUE', { field: 'logo_document', newValue })
    commit('CHANGE_VALUE', { field: 'logo_upload_id', newValue: newValue.id })
  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        cleanForm[key] = state.form[key]
      }

      commit('START_LOADING')

      let request = null

      if (id) {
        request = vm.$http.patch(`/admins/rest/v1/suppliers/${id}`, {
          supplier: cleanForm,
        })

      }
      else {
        request = vm.$http.post(`/admins/rest/v1/suppliers`, {
          supplier: cleanForm,
        })
      }

      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
}

export const $mutations: MutationTree<SupplierFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: SupplierFormState, { field, newValue }) {
    state.form[field] = newValue
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },

}

const $getters = {}

export const STORE_KEY = '$_supplier_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
